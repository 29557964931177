<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row
          v-if="dataDetail.leagues.length === 0"
          class="mb-1 d-flex align-items-center"
        >
          <b-col
            cols="3"
            class="font-weight-bold"
          >
            <h4 class="mb-0 mr-2">
              SPORT
            </h4>
          </b-col>
          <b-col
            cols="9"
            class="d-flex"
          >
            <b-form-select
              v-model="selectedSport"
              :options="optionsSport"
            />
            <b-button
              variant="primary"
              :disabled="loadingAddLeague || !selectedSport"
              class="btn-set ml-2"
              @click="handleAddLeague"
            >
              <Spinner v-if="loadingAddLeague" />
              <template v-else>
                Save
              </template>
            </b-button>
          </b-col>
        </b-row>

        <b-row
          v-if="dataDetail.leagues.length > 0"
          class="mb-1 d-flex align-items-center"
        >
          <h4 class="mb-0 mr-2 ml-2">
            SPORT : {{ dataDetail.leagues[0].sportLeagueId }}
          </h4>
          <b-button
            v-if="fetchListEvent.length === 0"
            variant="outline-primary"
            class="btn-set ml-2"
            @click="onClickInput"
          >
            <template> Add File List Event </template>
          </b-button>
          <input
            ref="fileInput"
            type="file"
            accept=".csv"
            hidden
            @change="handleFileUpload"
          >

          <b-button
            variant="outline-danger"
            class="btn-set ml-2"
            style="width: 134px; height: 38px"
            @click="showModal('remove-all')"
          >

            <template>
              Remove All
            </template>
          </b-button>
        </b-row>
      </div>

      <div v-if="dataDetail.leagues.length > 0">

        <!-- ROUND NAME -->
        <div
          v-if="currentPage > 0 && roundName && dataDetail.maxWeekNumber!== 0"
          class="d-flex mb-50 align-items-center"
        >
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4
            class="ml-50"
            style="font-weight: bold;"
          >
            {{ roundName && roundName.displayName? roundName.displayName : `Round ${roundName.weekNumber}`
            }}
          </h4>
          <b-button
            variant="primary"
            class="btn-set ml-2"
            @click="handleShowModalEditRoundName()"
          >
            <template> Edit Round Name </template>
          </b-button>
        </div>
        <!-- ROUND BONUS -->
        <div
          v-if="currentPage > 0 && roundName && dataDetail.maxWeekNumber !== 0"
          class="d-flex mb-2 align-items-center"
        >
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4
            class="ml-50"
            style="font-weight: bold;"
          >
            PERFECT ROUND BONUS: {{ roundName && !!Number(roundName.perfectRoundBonus)? roundName.perfectRoundBonus : ''
            }}
          </h4>
          <b-form-input
            v-model="bonusChange"
            :formatter="formatNumberPrize"
            style="width: 100px; text-align: center;"
            class="ml-2"
            placeholder="Enter..."
          />
          <b-button
            variant="primary"
            class="btn-set ml-2"
            @click="showModal('change-bonus')"
          >
            <template> Change </template>
          </b-button>
        </div>
        <b-table
          ref="refEventList"
          :items="fetchListEvent"
          :fields="columns"
          responsive
          primary-key="event"
          show-empty
        >
          <template #empty>
            <div
              v-if="fetchListEvent.length === 0"
              class="text-center my-2"
            >
              No matching records found
            </div>
          </template>
          <!-- raceNumber -->
          <template #cell(raceNumber)="{ item }">
            <b-media vertical-align="center">
              <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
                {{ item.number }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(raceName)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.name }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(date)="{ item }">
            <b-media vertical-align="center">
              <div
                class="d-flex align-items-center font-weight-bold"
                style="width: 90px"
              >
                {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
              </div>
            </b-media>
          </template>
          <!-- time -->
          <template #cell(time)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatTime(item.startTime) }}
              </div>
            </b-media>
          </template>
          <!-- shortNameHome -->
          <template #cell(shortNameHome)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatSortNameFlag(item.scores, 'HomeTeam') }}
              </div>
            </b-media>
          </template>

          <!-- shortNameAway -->
          <template #cell(shortNameAway)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatSortNameFlag(item.scores, 'AwayTeam') }}
              </div>
            </b-media>
          </template>

          <!-- flagHome -->
          <template #cell(flagHome)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatFlag(item.scores, 'HomeTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- flagAway -->
          <template #cell(flagAway)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatFlag(item.scores, 'AwayTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- subFlagHome -->
          <template #cell(subFlagHome)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatSubFlag(item.scores, 'HomeTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- subFlagAway -->
          <template #cell(subFlagAway)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatSubFlag(item.scores, 'AwayTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- teamAliasHome -->
          <template #cell(teamAliasHome)="{ item }">
            <div>{{ formatTeamAlias(item.scores, 'HomeTeam') }}</div>
          </template>
          <!-- teamAliasAway -->
          <template #cell(teamAliasAway)="{ item }">
            <div>{{ formatTeamAlias(item.scores, 'AwayTeam') }}</div>
          </template>

          <!-- action -->
          <template #cell(action)="{ item }">

            <b-dropdown
              variant="link"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="handleUpdate(item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> Update Real Event </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="handleUpdateInfo(item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> Update Info </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="handleUpdateOdds(item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> Update odds </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="item.eventId.includes('fake')"
                @click="handleShowModalScore(item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> Update Scores </span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleShowModalDetail(item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50"> Update Results </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showModal('recalculate', item)">
                <feather-icon
                  icon="CheckCircleIcon"
                />
                <span class="align-middle ml-50">Recalculate Leaderboard</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalPage"
                :per-page="10"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </div>

      <!-- MODAL LIST EVENT -->
      <b-modal
        ref="events"
        hide-footer
        title="Add event"
        centered
        cancel-variant="outline-secondary"
        size="xl"
        :busy="loadingPreviewEvent"
        @hide="handleHideModal"
      >
        <b-row class="d-flex justify-content-end mr-2 mt-2">
          <b-button
            variant="primary"
            class="btn-set ml-2 mb-2"
            @click="handleSaveListEvent"
          >
            <Spinner v-if="loadingSaveListEvent" />
            <template v-else>
              Save List Event
            </template>
          </b-button></b-row>
        <b-table
          ref="refEventListTable"
          :items="list"
          :fields="columns"
          responsive
          primary-key="event"
          show-empty
        >
          <template #empty>
            <div
              v-if="list.length === 0"
              class="text-center my-2"
            >
              No matching records found
            </div>
          </template>
          <!-- raceNumber -->
          <template #cell(raceNumber)="{ item }">
            <b-media vertical-align="center">
              <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
                {{ item.number }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(raceName)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.name }}
              </div>
            </b-media>
          </template>
          <!-- raceName -->
          <template #cell(date)="{ item }">
            <b-media vertical-align="center">
              <div
                class="d-flex align-items-center font-weight-bold"
                style="width: 90px"
              >
                {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
              </div>
            </b-media>
          </template>
          <!-- time -->
          <template #cell(time)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatTime(item.startTime) }}
              </div>
            </b-media>
          </template>
          <!-- shortNameHome -->
          <template #cell(shortNameHome)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatSortNameFlag(item.scores, 'HomeTeam') }}
              </div>
            </b-media>
          </template>

          <!-- shortNameAway -->
          <template #cell(shortNameAway)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ formatSortNameFlag(item.scores, 'AwayTeam') }}
              </div>
            </b-media>
          </template>

          <!-- flagHome -->
          <template #cell(flagHome)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatFlag(item.scores, 'HomeTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- flagAway -->
          <template #cell(flagAway)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatFlag(item.scores, 'AwayTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- subFlagHome -->
          <template #cell(subFlagHome)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatSubFlag(item.scores, 'HomeTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- subFlagAway -->
          <template #cell(subFlagAway)="{ item }">
            <b-media vertical-align="center">
              <img
                :src="formatSubFlag(item.scores, 'AwayTeam')"
                alt="flag"
                style="width: 35px; height: 35px"
              >
            </b-media>
          </template>

          <!-- teamAliasHome -->
          <template #cell(teamAliasHome)="{ item }">
            <div>{{ formatTeamAlias(item.scores, 'HomeTeam') }}</div>
          </template>
          <!-- teamAliasAway -->
          <template #cell(teamAliasAway)="{ item }">
            <div>{{ formatTeamAlias(item.scores, 'AwayTeam') }}</div>
          </template>

        </b-table>
      </b-modal>

      <!-- MODAL REPLACE REAL EVENT -->
      <b-modal
        ref="real-event"
        hide-footer
        title="Update Real Event"
        centered
        cancel-variant="outline-secondary"
        size="lg"
        :busy="loadingPreviewEvent"
        @hide="handleHideModal"
      >
        <div
          style="color: black;"
          class="mb-2"
        >
          <b-row class="mt-2">
            <b-col
              cols="3"
              md="2"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                EventId
              </div>
            </b-col>

            <b-col
              cols="9"
              class="d-flex align-items-center"
            >
              <!-- <b-row > -->
              <b-form-input
                v-model="realEventId"
                type="text"
                :no-wheel="true"
              />

              <b-button
                variant="primary"
                :disabled="loading"
                class="btn-set ml-2 "
                @click="handleCheckDataEventReal"
              >
                <Spinner v-if="loading" />
                <template v-else>
                  Check
                </template>
              </b-button>
              <!-- </b-row> -->
            </b-col>
          </b-row>
          <b-row
            v-if="dataRealEvent"
            class="mt-2 pl-2 pr-2"
            style="display: flex; flex-direction: column;"
          >
            <p><span class="font-weight-bolder">eventId:</span> {{ dataRealEvent.event.eventId }}</p>
            <p><span class="font-weight-bolder">eventTitle:</span> {{ dataRealEvent.event.eventTitle }}</p>
            <p><span class="font-weight-bolder">startTime:</span> {{ dataRealEvent.event.startTime }}</p>
            <div>
              <p class="font-weight-bolder">
                Primary Market:
              </p>
              <ol
                v-for="(item, index) in dataRealEvent.market.selections"
                :key="index"
              >
                <li>
                  <p><span class="font-weight-bolder">Role:</span> {{ item.role }}</p>
                  <p> <span class="font-weight-bolder">Name:</span> {{ item.name }}</p>
                  <p>
                    <span class="font-weight-bolder">winPrice:</span> {{ item.winPrice }}
                  </p>
                </li>
              </ol>

            </div>

          </b-row>
          <b-row
            v-if="dataRealEvent"
            class="d-flex justify-content-end mr-2 mt-2"
          >
            <b-button
              variant="primary"
              class="btn-set ml-2 mb-2"
              :disabled="loadingUpdateRealEvent"
              @click="handleSaveUpdateRealEvent"
            >
              <Spinner v-if="loadingUpdateRealEvent" />
              <template v-else>
                Update Real Event
              </template>
            </b-button></b-row>
        </div>

      </b-modal>

      <!-- MODAL UPDATE INFO EVENT -->
      <b-modal
        ref="info-event"
        hide-footer
        title="Update Info Event"
        centered
        cancel-variant="outline-secondary"
        size="md"
        @hide="handleHideModalInfo"
      >
        <div
          style="color: black;"
          class="mb-2"
        >

          <b-row
            v-if="updateInfoItem"
            class="mt-2 pl-2 pr-2"
            style="display: flex; flex-direction: column;"
          >
            <p><span class="font-weight-bolder">eventId:</span> {{ updateInfoItem.eventId }}</p>
            <p><span class="font-weight-bolder">eventTitle:</span> {{ updateInfoItem.eventTitle }}</p>
            <div>
              <p class="font-weight-bolder">
                Home Team:
              </p>
              <b-row class="mb-1">
                <b-col cols="4">
                  Flag:
                  <b-img
                    v-if="updateInfoItem.flagHome"
                    style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px"
                    :src="updateInfoItem.flagHome"
                    alt="logo"
                  />
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.flagHomeNew"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Sub Flag:
                  <b-img
                    v-if="updateInfoItem.subFlagHome"
                    style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px"
                    :src="updateInfoItem.subFlagHome"
                    alt="logo"
                  />
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.subFlagHomeNew"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Name:
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.flagNameHome"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Character:
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.teamAliasHome"
                    type="text"
                  />
                </b-col>
              </b-row>
            </div>
            <div>
              <p class="font-weight-bolder">
                Away Team:
              </p>
              <b-row class="mb-1">
                <b-col cols="4">
                  Flag:
                  <b-img
                    v-if="updateInfoItem.flagAway"
                    style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px"
                    :src="updateInfoItem.flagAway"
                    alt="logo"
                  />
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.flagAwayNew"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Sub Flag:
                  <b-img
                    v-if="updateInfoItem.subFlagAway"
                    style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px"
                    :src="updateInfoItem.subFlagAway"
                    alt="logo"
                  />
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.subFlagAwayNew"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Name:
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.flagNameAway"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="4">
                  Character:
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    v-model="updateInfoItem.teamAliasAway"
                    type="text"
                  />
                </b-col>
              </b-row>
            </div>

          </b-row>
          <b-row
            v-if="updateInfoItem"
            class="d-flex justify-content-end mr-2 mt-2"
          >
            <b-button
              variant="primary"
              class="btn-set ml-2 mb-2"
              :disabled="loading"
              @click="handleSaveUpdateInfoEvent"
            >
              <Spinner v-if="loading" />
              <template v-else>
                Save
              </template>
            </b-button></b-row>
        </div>

      </b-modal>

      <!-- MODAL UPDATE ODDS -->
      <b-modal
        ref="edit-selection"
        title="Update Odds"
        centered
        cancel-variant="outline-secondary"
        size="md"
        hide-footer
      >
        <div
          v-if="selectedOddsItem && selectedOddsItem.markets"
          style="color: black;"
        >

          <div
            v-for="item in selectedOddsItem.markets[0].selections"
            :key="item.marketSelectionId"
            style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
          >
            <p>
              <span style="font-weight: bold">Selection Name: </span>  {{ item.selectionName }}
            </p>
            <p>
              <span style="font-weight: bold"> Role:</span>   {{ item.selectionRole }}
            </p>

            <!-- winPriceResult -->
            <b-row class="">
              <b-col
                cols="3"
                class="d-flex align-items-center"
              >
                <div class="font-weight-bold">
                  winPrice
                </div>
              </b-col>
              <b-col
                cols="8"
                class="d-flex"
                style="flex-direction: column; gap: 12px"
              >
                <b-form-input
                  v-model="item.originalWinPrice"
                  :formatter="formatPrice"
                />
              </b-col>
            </b-row>

          </div>
        </div>

        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveNewOdds"
            >
              <Spinner v-if="loadingUpdateOdd" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <!--  SCORE MODAL -->
      <b-modal
        ref="scores"
        title="Edit Scores"
        centered
        cancel-variant="outline-secondary"
        :ok-disabled="loadingInputResultAuto"
        @ok="handleSubmitModalChangeScore"
      >
        <b-row class="mt-2 text-title">
          <b-col
            cols="8"
            class="d-flex align-items-center"
          >
            <div>
              Team Home: <span style="font-weight: 900;">{{ homeTeam }}</span>
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="homeScore"
              type="text"
              placeholder="Add score..."
            />
          </b-col>
        </b-row>
        <b-row class="mt-2 text-title">
          <b-col
            cols="8"
            class="d-flex align-items-center"
          >
            <div class="font-weight-bold">
              Team Away: <span style="font-weight: 900;">{{ awayTeam }}</span>
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex"
            style="flex-direction: column"
          >
            <b-form-input
              v-model="awayScore"
              type="text"
              placeholder="Add score..."
            />
          </b-col>
        </b-row>

        <template #modal-ok>
          <Spinner v-if="loadingInputResultAuto" />
          <span v-else>Submit</span>
        </template>
      </b-modal>

      <!-- MODAL RESULT -->
      <b-modal
        ref="edit-result"
        title="Update Result"
        centered
        cancel-variant="outline-secondary"
        size="md"
        hide-footer
      >
        <div
          v-if="selectedMarket && selectedMarket.selections"
          style="color: black;"
        >

          <div
            v-for="item in selectedMarket.selections"
            :key="item.marketSelectionId"
            style="padding-bottom: 10px; border-bottom: 1px solid black; margin-bottom: 10px;"
          >
            <p>
              <span style="font-weight: bold">Selection Name: </span>  {{ item.selectionName }}
            </p>
            <p>
              <span style="font-weight: bold"> Role:</span>   {{ item.role }}
            </p>

            <!-- RESULT -->
            <b-row class="mt-2">
              <b-col
                cols="3"
                class="d-flex align-items-center"
              >
                <div class="font-weight-bold">
                  Result
                </div>
              </b-col>
              <b-col
                cols="8"
                class="d-flex"
                style="flex-direction: column"
              >
                <b-form-radio-group
                  :id="`radio-inline-${item.role}`"
                  v-model="item.result"
                  :options="optionsResult"
                  :name="`radio-inline-${item.role}`"
                />
              </b-col>
            </b-row>

            <!-- winPriceResult -->
            <b-row class="mt-2">
              <b-col
                cols="3"
                class="d-flex align-items-center"
              >
                <div class="font-weight-bold">
                  winPriceResult
                </div>
              </b-col>
              <b-col
                cols="8"
                class="d-flex"
                style="flex-direction: column; gap: 12px"
              >
                <b-form-input
                  v-model="item.winPriceResult"
                  :formatter="formatPrice"
                />
              </b-col>
            </b-row>

          </div>

        </div>

        <b-row>
          <b-col class="d-flex justify-content-end mt-2">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleEditSelection"
            >
              <Spinner v-if="loadingChangeSelection" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <!--  ACTION MODAL -->
      <b-modal
        ref="modal"
        :title="modal.title"
        centered
        cancel-variant="outline-secondary"
        :ok-disabled="loadingInputResultAuto || loadingRemoveAll || loadingUpdateRoundBonus"
        @ok="handleSubmitModal"
      >
        <b-card-text class="text-title">
          {{ modal.content }}
        </b-card-text>
        <template #modal-ok>
          <Spinner v-if="loadingInputResultAuto || loadingRemoveAll || loadingUpdateRoundBonus" />
          <span v-else>Submit</span>
        </template>
      </b-modal>

      <!-- UPDATE NAME ROUND -->
      <b-modal
        ref="round-name"
        hide-footer
        title="Update Round Name"
        centered
        cancel-variant="outline-secondary"
        size="md"
        @hide="handleHideModalRoundName"
      >
        <div style="color: black;">

          <!-- Prize Pool -->
          <b-row
            v-if="updateRoundName"
            class="mt-1"
          >
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Round Number {{ updateRoundName.weekNumber }}:
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-input
                v-model="updateRoundName.displayName"
                placeholder="Enter Name..."
                type="text"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loadingUpdateNameWeek"
                @click="handleSaveRoundName"
              >
                <Spinner v-if="loadingUpdateNameWeek" />
                <template v-else>
                  Save
                </template>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BTable, BMedia, BCol, BRow, BCard, BButton, BFormSelect,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BImg,
  BFormRadioGroup,
  BCardText,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import {
  formatDate, formatDateTimeAustralia, formatDateQuery, formatTime, formatDateTimeLocal, convertToUTC,
} from '@/helpers/utils'
import { arrayProp } from '@/helpers/props.js'
import useToastHandler from '@/services/toastHandler'
import { parse } from 'csv-parse'
// import api from '@/services/api'
import Spinner from '@core/components/Spinner'
import { v4 as uuidv4 } from 'uuid'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BTable,
    BMedia,
    BCol,
    BRow,
    BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormSelect,
    Spinner,
    BPagination,
    BFormInput,
    BImg,
    BFormRadioGroup,
    BCardText,
  },
  props: {
    listDetailEventProp: arrayProp(),
  },

  data() {
    return {
      eventId: null,
      eventItem: null,
      eventDetailId: null,
      eventDetailItem: null,
      meetingsDated: [],
      listRace: [],
      formatDateTimeAustralia,
      formatDate,
      sortOrderStartTime: 'ASC',
      sortOrderRunners: '',
      listEvent: [],
      listSeletedLeg: [],
      listDetailEvent: [],
      listSeletedMarkets: [],
      selectedRowId: 2,
      isEdit: false,

      selectedListFormat: [],

      optionsSport: [],
      selectedSport: '',
      loadingAddLeague: false,
      list: [],
      loadingRemoveAll: false,
      loadingPreviewEvent: false,
      loadingSaveListEvent: false,

      realEventId: null,
      eventFakeUpdate: null,
      dataRealEvent: null,

      updateInfoItem: null,
      flagHome: null,

      selectedOddsItem: null,

      eventIdSelected: null,
      homeScore: '',
      homeTeam: '',
      awayScore: '',
      awayTeam: '',
      selectedMarket: null,
      optionsResult: [
        { text: 'Win', value: 'Win' },
        { text: 'Lose', value: 'Lose' },
      ],
      modal: {
        title: '',
        content: '',
      },
      action: {
        type: '',
        id: '',
      },

      listWeekNameUpdate: [],
      currentRoundName: null,
      updateRoundName: null,
      roundName: null,

      listBonusRound: [],
      bonusChange: null,
    }
  },
  computed: {
    ...mapState('sportComp/eventDetail', ['loading', 'detailEvent', 'loadingChangeSelection']),
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('tipping/create', ['loadingUpdateRealEvent', 'loadingUpdateOdd']),
    ...mapState('sportComp/updateComp', ['loading']),
    ...mapState('sportComp/eventDetail', ['loadingInputResultAuto']),
    ...mapState('tipping/payout', ['loadingUpdateNameWeek', 'loadingUpdateRoundBonus']),
    converTime() {
      return new Date(this.time).toISOString()
    },
  },
  watch: {
    selectedSport() {
      this.refEventListTable.refresh()
    },
    optionsSport() {
      if (!this.refEventListTable) return
      this.refEventListTable.refresh()
    },
    dataDetail() {
      if (this.dataDetail.maxWeekNumber) this.totalPage = this.dataDetail.maxWeekNumber * 10
    },
  },
  async created() {
    if (this.dataDetail.maxWeekNumber) this.totalPage = this.dataDetail.maxWeekNumber * 10
    const res = await this.getListLeaguesSport()
    const listLeague = res.filter(i => i.status !== 'disable')
    if (Array.isArray(listLeague)) {
      const formatList = listLeague.map(i => ({
        text: i.sportLeagueId,
        value: i.sportLeagueId,
        sportCode: i.sportCode,
      }))
      this.optionsSport = formatList
    }
    this.listDetailEvent = this.listDetailEventProp
    const list = this.listDetailEventProp.map(i => ({
      ...i,
      legNumber: i.number,
    }))
    this.listSeletedLeg = list

    let listAllMarket = []
    this.listDetailEventProp.forEach(i => {
      listAllMarket = [...listAllMarket, ...i.markets]
    })
    if (listAllMarket.length > 0) this.isEdit = true
  },
  methods: {
    ...mapActions({
      getListEvent: 'sportComp/listEvent/getListEvent',
      getDetail: 'sportComp/eventDetail/getDetail',
      getListLeaguesSport: 'tipping/league/getListLeagues',
      handleAddLeagueToComp: 'sportComp/createComp/addLeagueToComp',
      getCompDetail: 'races/compDetail/getCompDetail',
      handleAddSportEventsToComp: 'sportComp/createComp/addSportEventsToComp',
      handleAddSportMarketsToComp: 'sportComp/createComp/addSportMarketsToComp',
      removeAllEvent: 'sportComp/createComp/removeAllEvent',
      removeLeagueToComp: 'sportComp/updateComp/removeLeagueToComp',
      getListEventTipping: 'tipping/create/getListEventTipping',
      updateRealEvent: 'tipping/create/updateRealEvent',
      changeFlagEvent: 'sportComp/updateComp/changeFlagEvent',
      updateNewOdds: 'tipping/create/updateNewOdds',
      getMarketOfEvent: 'tipping/create/getMarketOfEvent',
      changeSelection: 'sportComp/eventDetail/changeSelection',
      recalculateUserTotalPoint: 'sportComp/eventDetail/recalculateUserTotalPoint',

      updateNameWeek: 'tipping/payout/updateNameWeek',
      getListWeek: 'tipping/payout/getListWeek',
      updateRoundBonus: 'tipping/payout/updateRoundBonus',
    }),
    async fetchListEvent() {
      const res = await this.getListEventTipping({
        competitionId: this.$route.params.id,
        fromWeek: this.currentPage,
        toWeek: this.currentPage,
      })
      this.getRoundNameWeek()
      return res ?? []
    },
    async getRoundNameWeek() {
      const resName = await this.getListWeek({
        competitionId: this.$route.params.id,
        weekNumber: this.currentPage,
      })
      if (resName && resName.length > 0) {
        this.updateRoundName = resName[0]
        this.roundName = resName[0]
      }
    },
    onClickInput() {
      const fileInput = this.$refs.fileInput
      fileInput.click()
      this.loadingPreviewEvent = true
    },
    async handleAddLeague() {
      this.loadingAddLeague = true

      if (this.dataDetail.leagues.length > 0) {
        await this.removeLeagueToComp({
          competitionId: this.$route.params.id,
          leagueId: this.dataDetail.leagues[0].sportLeagueId,
        })
      }
      const resLeague = await this.handleAddLeagueToComp({
        competitionId: this.$route.params.id,
        sportLeagueId: this.selectedSport,
      })
      if (resLeague) {
        this.showToastSuccess('Success', 'Add league successfully!')
        this.getCompDetail(this.$route.params.id)
      }
      this.loadingAddLeague = false
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const idSet = new Set()
        const newList = data.map(i => {
          let newId
          do {
            newId = `fake-${uuidv4()}`
          } while (idSet.has(newId))

          idSet.add(newId)
          return {
            orderNumber: Number(i[0]),
            weekNumber: Number(i[1]),
            startTime: this.convertToUTC(i[4]),
            stadium: i[7],
            scores: [
              {
                flag: i[10],
                subFlag: i[12],
                flagName: i[8],
                team: i[14],
                role: 'HomeTeam',
                teamAlias: i[16],
              }, {
                flag: i[11],
                subFlag: i[13],
                flagName: i[9],
                team: i[15],
                role: 'AwayTeam',
                teamAlias: i[17],
              },
            ],
            eventId: newId,
            eventTitle: `${i[14]} vs ${i[15]}`,
          }
        })
        this.$refs.events.show()
        this.list = newList

        const roundNameList = data.map(i => ({
          weekNumber: Number(i[1]),
          displayName: i[2],
        }))
        const uniqueArr = [...new Map(roundNameList.map(item => [item.weekNumber, item])).values()]
        this.listWeekNameUpdate = uniqueArr

        const roundBonusList = data.map(i => ({
          weekNumber: Number(i[1]),
          perfectRoundBonus: i[18],
        }))
        const uniqueArrBonus = [...new Map(roundBonusList.map(item => [item.weekNumber, item])).values()]
        this.listBonusRound = uniqueArrBonus.filter(i => !!i.perfectRoundBonus)
      })
    },
    formatSortNameFlag(scores, role) {
      return scores.find(i => i.role === role).flagName
    },
    formatFlag(scores, role) {
      return scores.find(i => i.role === role).flag
    },
    formatSubFlag(scores, role) {
      return scores.find(i => i.role === role).subFlag
    },
    formatTeamAlias(scores, role) {
      return scores.find(i => i.role === role).teamAlias
    },
    formatListMarket(list) {
      const listMarket = list.map(i => {
        const idSet = new Set()
        const formatSelectionList = i.scores.map(sel => {
          let newId
          do {
            newId = `fake-${uuidv4()}`
          } while (idSet.has(newId))

          idSet.add(newId)
          return ({
            name: sel.team,
            role: sel.role === 'HomeTeam' ? 'Home' : 'Away',
            winPrice: '',
            marketSelectionId: newId,
          })
        })

        let newId
        do {
          newId = `fake-${uuidv4()}`
        } while (idSet.has(newId))

        idSet.add(newId)
        return {
          eventId: i.eventId,
          name: 'Market',
          startTime: i.startTime,
          marketType: 'Straight',
          selectionLimit: 1,
          selections: formatSelectionList,
          marketId: newId,
        }
      })
      return listMarket
    },
    async handleSaveListEvent() {
      this.loadingSaveListEvent = true
      const format = this.list.map(i => ({
        eventId: i.eventId,
        eventTitle: i.eventTitle,
        startTime: i.startTime,
        sport: this.dataDetail.leagues[0].leagueName,
        league: this.dataDetail.leagues[0].sportLeagueId,
        country: '',
        primaryMarketName: '',
        displayStatus: '',
        bettingStatus: '',
        detailLink: '',
        brandLinksLadbrokes: '',
        brandLinksNeds: '',
        eventType: '',
        entryType: 0,
        orderNumber: i.orderNumber,
        stadium: i.stadium,
        scores: i.scores,
        weekNumber: i.weekNumber,
      }))

      const formatList = this.splitArray(format)

      // eslint-disable-next-line no-restricted-syntax
      for (const item of formatList) {
        // eslint-disable-next-line no-await-in-loop
        const result = await this.handleAddSportEventsToComp({
          competitionId: this.$route.params.id,
          events: item,
        })
        if (!result) return this.loadingSaveListEvent = false
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of formatList) {
        // eslint-disable-next-line no-await-in-loop
        const result = await this.handleAddSportMarketsToComp({
          competitionId: this.$route.params.id,
          eventId: '1111',
          markets: this.formatListMarket(item),
        })
        if (!result) return this.loadingSaveListEvent = false
      }

      if (this.listWeekNameUpdate.length > 0) {
        const formatListWeekName = this.splitArray(this.listWeekNameUpdate)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of formatListWeekName) {
        // eslint-disable-next-line no-await-in-loop
          const result = await this.updateNameWeek({
            competitionId: this.$route.params.id,
            weeks: item,
          })
          if (!result) return this.loadingSaveListEvent = false
        }
      }

      if (this.listBonusRound.length > 0) {
        const formatListBonus = this.splitArray(this.listBonusRound)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of formatListBonus) {
        // eslint-disable-next-line no-await-in-loop
          const result = await this.updateRoundBonus({
            competitionId: this.$route.params.id,
            rounds: item,
          })
          if (!result) return this.loadingSaveListEvent = false
        }
      }

      this.showToastSuccess('Success', 'Add event list successfully!')
      this.handleHideModal()
      this.list = []
      this.reloadTable()
      this.loadingSaveListEvent = false
      this.getCompDetail(this.$route.params.id)
    },
    async handleRemoveAllEvent() {
      this.loadingRemoveAll = true
      await this.removeAllEvent(this.$route.params.id)
      this.loadingRemoveAll = false
      this.totalPage = 0
      this.reloadTable()
      this.getCompDetail(this.$route.params.id)
      this.roundName = null
    },
    handleHideModal() {
      this.$refs.events.hide()
    },
    async reloadTable() {
      this.refetchData()
    },
    splitArray(array) {
      const result = []
      const length = array.length
      for (let i = 0; i < length; i += 50) {
        result.push(array.slice(i, i + 50))
      }
      return result
    },
    async handleCheckDataEventReal() {
      const res = await this.getDetail(this.realEventId)
      if (!res.markets) return this.showToastError(false, 'Event does not have market!')
      const primaryMarket = res.markets.find(i => i.name === res.primaryMarketName)
      if (!primaryMarket) return this.showToastError(false, 'Event does not have primary market!')
      const data = {
        oldEventId: this.eventFakeUpdate.eventId,
        event: {
          eventId: res.eventID,
          eventTitle: res.eventTitle,
          startTime: res.startTime,
          sport: res.sport,
          country: res.country,
          league: res.league,
          primaryMarketName: res.primaryMarketName,
          displayStatus: res.displayStatus,
          bettingStatus: res.bettingStatus,
          detailLink: '',
          brandLinksLadbrokes: res.brandLinks.ladbrokes,
          brandLinksNeds: res.brandLinks.neds,
          eventType: res.eventType,
          entryType: 0,
          orderNumber: this.eventFakeUpdate.orderNumber,
          stadium: this.eventFakeUpdate.stadium,
          weekNumber: this.eventFakeUpdate.weekNumber,
          scores: this.eventFakeUpdate.scores.map(i => {
            const selection = primaryMarket.selections.find(sel => i.role.includes(sel.role))
            return {
              ...i,
              team: selection.name,
            }
          }),

        },
        market: {
          name: primaryMarket.name,
          startTime: primaryMarket.startTime,
          marketType: primaryMarket.marketType,
          selectionLimit: 1,
          orderNumber: 1,
          selections: primaryMarket.selections.map(sel => ({
            name: sel.name,
            role: sel.role,
            winPrice: sel.winPrice,
            marketSelectionId: sel.externalID,
          })),
          marketId: primaryMarket.externalID,
        },
      }
      this.dataRealEvent = data
    },
    handleUpdate(item) {
      this.eventFakeUpdate = item
      this.$refs['real-event'].show()
    },
    async handleSaveUpdateRealEvent() {
      const res = await this.updateRealEvent({
        competitionId: this.$route.params.id,
        data: this.dataRealEvent,
      })
      if (res) {
        this.showToastSuccess('Success', 'Update real event successfully!')
        this.reloadTable()
        this.$refs['real-event'].hide()
        this.realEventId = null
        this.eventFakeUpdate = null
        this.dataRealEvent = null
      }
    },
    handleUpdateInfo(item) {
      const home = item.scores.find(i => i.role === 'HomeTeam')
      const away = item.scores.find(i => i.role === 'AwayTeam')
      this.updateInfoItem = {
        eventId: item.eventId,
        eventTitle: item.eventTitle,
        teamHome: home.team,
        scoreHome: home.score,
        flagHome: home.flag,
        flagHomeNew: home.flag,
        flagNameHome: home.flagName,
        subFlagHome: home.subFlag,
        subFlagHomeNew: home.subFlag,
        teamAliasHome: home.teamAlias,
        flagAway: away.flag,
        flagAwayNew: away.flag,
        flagNameAway: away.flagName,
        subFlagAway: away.subFlag,
        subFlagAwayNew: away.subFlag,
        teamAliasAway: away.teamAlias,
        teamAway: away.team,
        scoreAway: away.score,

      }
      this.flagHome = home.flag
      this.$refs['info-event'].show()
    },
    handleHideModalInfo() {
      this.updateInfoItem = null
    },
    async handleSaveUpdateInfoEvent() {
      const res = await this.changeFlagEvent({
        eventId: this.updateInfoItem.eventId,
        data: {
          scores: [
            {
              role: 'HomeTeam',
              flag: this.updateInfoItem.flagHomeNew,
              subFlag: this.updateInfoItem.subFlagHomeNew,
              flagName: this.updateInfoItem.flagNameHome,
              teamAlias: this.updateInfoItem.teamAliasHome,
              team: this.updateInfoItem.teamHome,
              score: this.updateInfoItem.scoreHome,

            },
            {
              role: 'AwayTeam',
              flag: this.updateInfoItem.flagAwayNew,
              subFlag: this.updateInfoItem.subFlagAwayNew,
              flagName: this.updateInfoItem.flagNameAway,
              teamAlias: this.updateInfoItem.teamAliasAway,
              team: this.updateInfoItem.teamAway,
              score: this.updateInfoItem.scoreAway,

            },
          ],
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Change info event successfully!')
        this.$refs['info-event'].hide()
        this.reloadTable()
      }
    },
    handleUpdateOdds(item) {
      this.selectedOddsItem = item
      this.$refs['edit-selection'].show()
    },
    formatPrice(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleSaveNewOdds() {
      if (!this.selectedOddsItem) return
      const res = await this.updateNewOdds({
        marketSelections: this.selectedOddsItem.markets[0].selections.map(i => ({
          marketSelectionId: i.marketSelectionId,
          originalWinPrice: i.originalWinPrice,
        })),
      })
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.$refs['edit-selection'].hide()
      }
    },
    handleShowModalScore(item) {
      this.eventIdSelected = item
      const home = item.scores.find(i => i.role === 'HomeTeam')
      this.homeScore = home.score
      this.homeTeam = home.team
      const away = item.scores.find(i => i.role === 'AwayTeam')
      this.awayScore = away.score
      this.awayTeam = away.team
      this.$refs.scores.show()
    },
    async handleSubmitModalChangeScore() {
      const scores = [
        { role: 'HomeTeam', team: this.homeTeam, score: this.homeScore },
        { role: 'AwayTeam', team: this.awayTeam, score: this.awayScore },
      ]

      const res = await this.changeFlagEvent({
        eventId: this.eventIdSelected.eventId,
        data: {
          scores,
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Input result automatically successfully!')
        this.refetchData()
      }
    },
    async handleShowModalDetail(item) {
      const res = await this.getMarketOfEvent(item.eventId)
      this.selectedMarket = res
      this.eventIdSelected = item
      if (item.eventId.includes('fake')) {
        return this.$refs['edit-result'].show()
      }
    },
    async handleEditSelection() {
      if (this.selectedMarket.selections.some(i => !i.result || !i.winPriceResult)) return this.showToastError('Error', 'Please fill all data!')
      const checkIsDraw = this.selectedMarket.selections[0].result === this.selectedMarket.selections[1].result
      const data = {
        EventID: this.eventIdSelected.eventId,
        Markets: [
          {
            ExternalID: this.selectedMarket.marketId,
            MarketType: this.selectedMarket.marketType,
            isPrimaryMarket: this.selectedMarket.name === this.eventIdSelected.primaryMarketName,
            Selections: this.selectedMarket.selections.map(se => ({
              ExternalID: se.marketSelectionId,
              Role: se.role,
              WinPrice: Number(se.winPriceResult),
              Result: se.result,
              ...(checkIsDraw && { type: 'draw' }),
            })),

          },
        ],
        isFinished: true,
      }
      const res = await this.changeSelection(data)
      if (res) {
        this.showToastSuccess('Success', 'Update data selection successfully!')
        this.refetchData()
        this.$refs['edit-result'].hide()
      }
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },
    showModal(type, id) {
      this.action = { id, type }
      if (type === 'recalculate') {
        this.setModal('Recalculate', `Are you sure you want to recalculate user totalPoint of event ${id.eventId}?`)
      }
      if (type === 'remove-all') {
        this.setModal('Remove All Event', 'Are you sure you want to remove all event?')
      }

      if (type === 'change-bonus') {
        if (!this.bonusChange) return this.showToastError('Error', 'Please enter perfect round bonus')
        this.setModal('Perfect Round Bonus', 'Are you sure you want to change this bonus round?')
      }

      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      const { id, type } = this.action

      if (type === 'recalculate') {
        const result = await this.recalculateUserTotalPoint(id.eventId)
        if (!result) return
        this.showToastSuccess('Success', 'Recalculate leaderboard successfully!')
      }
      if (type === 'remove-all') {
        await this.handleRemoveAllEvent()
      }

      if (type === 'change-bonus') {
        await this.handleShowModalEditRoundBonus()
      }

      if (!this.loadingInputResultAuto && !this.loadingRemoveAll && !this.loadingUpdateRoundBonus) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    handleShowModalEditRoundName() {
      this.$refs['round-name'].show()
    },
    handleHideModalRoundName() {

    },
    async  handleSaveRoundName() {
      const res = await this.updateNameWeek({
        competitionId: this.$route.params.id,
        weeks: [
          {
            weekNumber: this.updateRoundName.weekNumber,
            displayName: this.updateRoundName.displayName,
          },
        ],
      })
      if (res) {
        this.showToastSuccess('Success', 'Update round name successfully!')
        this.getRoundNameWeek()
        this.$refs['round-name'].hide()
      }
    },
    formatNumberPrize(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    async handleShowModalEditRoundBonus() {
      const res = await this.updateRoundBonus({
        competitionId: this.$route.params.id,
        rounds: [{
          weekNumber: this.roundName.weekNumber,
          perfectRoundBonus: Number(this.bonusChange),
        }],
      })
      if (res) {
        this.showToastSuccess('Success', 'Update round name successfully!')
        this.getRoundNameWeek()
        this.bonusChange = null
      }
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const columns = [
      {
        key: 'weekNumber',
      },
      {
        key: 'eventId',
      },
      {
        key: 'eventTitle',
        label: 'Event name',
      },
      {
        key: 'date',
      },
      {
        key: 'time',
        label: 'START TIME',
      },
      {
        key: 'shortNameHome',
      },
      {
        key: 'flagHome',
      },
      {
        key: 'subFlagHome',
      },
      {
        key: 'teamAliasHome', label: 'Home Character',
      },
      {
        key: 'shortNameAway',
      },
      {
        key: 'flagAway',
      },
      {
        key: 'subFlagAway',
      },
      {
        key: 'teamAliasAway', label: 'Away Character',
      },
      {
        key: 'stadium',
      },
      {
        key: 'action',
      },
    ]
    const refEventListTable = ref(null)
    const refEventList = ref(null)

    const currentPage = ref(1)
    const totalPage = ref(0)

    const refetchData = () => {
      refEventList.value.refresh()
    }

    watch([currentPage], () => {
      refetchData()
    })

    return {
      columns,
      refEventListTable,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,

      showToastSuccess,
      showToastError,

      refEventList,

      convertToUTC,

      currentPage,
      totalPage,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
.dark-layout .dropdown-menu .dropdown-item.disabled {
  color: red !important;
}
</style>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}

.card-body {
  padding: 0 !important;
}

.per-page-selector {
  width: 90px;
}

.id-column {
  max-width: 150px;
  min-width: 100px;
  word-break: break-word;
}

.text-title {
  color: black;
  font-weight: 500;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
