<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            DATA COMP
          </h4>
        </div>

        <p><span class="title">Real Cash Betslips:</span> {{ compStatistic.cashBetSlip }}</p>
        <p><span class="title">Free Betslips:</span> {{ compStatistic.freeBetSlip }}</p>
        <p><span class="title">Total Betslips:</span> {{ dataDetail.participants }}</p>
        <p><span class="title">Participants Unique:</span> {{ dataDetail.uniqueParticipants }}</p>

        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            UPDATE COMP
          </h4>
        </div>

        <validation-observer ref="infoTippingForm">
          <!-- Form: Information Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- COMP NAME -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (logged in)</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="compName"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- COMP NAME -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (not logged in)</label>
                  </div>
                  <b-form-input
                    v-model="guestName"
                    type="text"
                  />
                </b-form-group>
              </b-col>

              <!-- Showdown Open Date/Time -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="openDate">Showdown Open Date/Time</label>
                  </div>
                  <validation-provider
                    name="openDate"
                    vid="openDate"
                  >
                    <flat-pickr
                      id="openDate"
                      v-model="openDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                      // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- First Leg Start Date/Time -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="competitionDate">First Leg Start Date/Time</label>
                  </div>
                  <validation-provider
                    name="competitionDate"
                    vid="competitionDate"
                  >
                    <flat-pickr
                      id="competitionDate"
                      v-model="competitionDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                      // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--  END DATE/TIME -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="END DATE/TIME">END DATE/TIME</label>
                  </div>
                  <validation-provider
                    name="endTime"
                    vid="endTime"
                  >
                    <flat-pickr
                      id="endDate"
                      v-model="endDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                        disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--MORE INFO -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="more info">MORE INFO</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="moreInfo"
                    vid="moreInfo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="moreInfo"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--REMEMBER -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="remember">REMEMBER</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="remember"
                    vid="remember"
                    rules="required"
                  >
                    <b-form-input
                      v-model="remember"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--SCRATCHINGS -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="scratchings">SCRATCHINGS</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="scratchings"
                    vid="scratchings"
                    rules="required"
                  >
                    <b-form-input
                      v-model="scratchings"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- ENTRY FEE -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="entry fee">ENTRY FEE</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="priceTo"
                    vid="priceTo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="priceTo"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- RE BUY FEE -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">RE BUY FEE</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="latePrice"
                    vid="latePrice"
                    rules="required"
                  >
                    <b-form-input
                      v-model="latePrice"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- LIMIT REAL BETSLIP -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">LIMIT BETSLIP</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="limit betslip"
                    vid="limit betslip"
                    rules="required"
                  >
                    <b-form-input
                      id="limit-value"
                      v-model="limitBetslip"
                      :formatter="formatNumberBetslip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- MANUAL BETSLIP -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">MANUAL BETSLIP ENTRY (optional)</label>
                  </div>
                  <b-form-input
                    id="limit-value"
                    v-model="manualBetSlipEntry"
                    :formatter="formatNumberBetslip"
                  />
                </b-form-group>
              </b-col>

              <!-- Api Price -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">BONUS ADDITIONAL TICKET (optional)</label>
                  </div>
                  <b-form-input
                    v-model="apiPrice"
                    min="0"
                    type="number"
                    :no-wheel="true"
                    placeholder="Enter 100 for 0.5 tickets @ $50"
                  />
                </b-form-group>
              </b-col>

              <!--PARTICIPANTS DESCRIPTIONS -->
              <b-col cols="12">
                <b-form-group>
                  <div class="d-flex">
                    <label for="participants descriptions">PARTICIPANTS DESCRIPTIONS</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="participantsDes"
                    vid="participantsDes"
                    rules="required|limitCharacters"
                  >
                    <!-- <vue-editor
                      v-model="participantsDes"
                      name="participantsDes"
                      :editor-toolbar="customToolbar"
                    /> -->
                    <vue2-tinymce-editor v-model="participantsDes" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <!-- PRIZE TYPE -->
            <b-row class="mt-2 d-flex align-items-start">
              <b-col
                cols="3"
                class="font-weight-bold"
              >
                <h4>Transfer Prize By</h4>
              </b-col>
              <b-col cols="9">
                <b-form-radio-group
                  id="transfer-prize"
                  v-model="selectedTransferPrize"
                  :options="optionsTransferPrizeBy"
                  name="transfer-prize"
                />
              </b-col>
            </b-row>

            <b-row class="mt-4 mb-4">
              <b-col class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="loadingUpdateComp"
                  @click="handleUpdateComp"
                >
                  <Spinner v-if="loadingUpdateComp" />
                  <template v-else>
                    Save changes
                  </template>
                </b-button>
                <b-button
                  variant="outline-secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="resetData"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BCard,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useToastHandler from '@/services/toastHandler'
import { mapState, mapActions } from 'vuex'
import { objectProp } from '@/helpers/props'
import flatPickr from 'vue-flatpickr-component'
import { subDays, subHours, subSeconds } from 'date-fns'
import {
  formatDate, formatDateSend, formatDateTimeLocal, toCardinal, formatCurrencyBuiltInFunc, formatTime,
} from '@/helpers/utils'
// import { VueEditor } from 'vue2-editor'
import Spinner from '@core/components/Spinner'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    // VueEditor,
    Spinner,
    BFormRadioGroup,
    BCard,
    Vue2TinymceEditor,
  },
  props: {
    comp: objectProp(),
  },
  data() {
    return {
      toCardinal,
      compName: this.comp.name,
      openDate: new Date(this.comp.openForEntry),
      competitionDate: new Date(this.comp.competitionDate),
      endDate: new Date(this.comp.endDate),
      moreInfo: this.comp.moreInfo,
      remember: this.comp.remember,
      scratchings: this.comp.scratchings,
      percentWinner: this.comp.percentWinner,
      participantsDes: this.comp.participantDescriptions,
      latePrice: this.comp.latePrice,
      priceTo: this.comp.price,
      guestName: this.comp.guestName,

      morningLockout: new Date(this.comp.morningLockout),
      afternoonLockout: new Date(this.comp.afternoonLockout),

      disableDate: subDays(new Date(), 1),
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
        ['clean'],
        ['horizontalLine'],
      ],
      apiPrice: this.comp.apiPrice ? this.comp.apiPrice : null,
      limitBetslip: this.comp.joinTimesLimit || null,
      manualBetSlipEntry: this.comp.manualBetSlipEntry || null,
      selectedTransferPrize: this.comp.prizeType,
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],
    }
  },

  computed: {
    ...mapState('races/compDetail', ['dataDetail', 'compStatistic']),
    ...mapState('races/updateComp', ['loadingUpdateComp']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
    disableChangeMode() {
      if (this.dataDetail.prizeMode === 'api') return
      // eslint-disable-next-line no-unused-expressions
      new Date(this.dataDetail.morningLockout) < new Date()

      return subHours(new Date(this.dataDetail.morningLockout), 1) < new Date()
    },
  },
  async created() {
    await this.getCompStatistic(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      updateComp: 'races/updateComp/updateCompAsync',
      getCompDetail: 'races/compDetail/getCompDetail',
      getCompStatistic: 'races/compDetail/getCompStatistic',

    }),
    formatter(value) {
      let val = value.replace(/[^0-9.]/g, '')
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      if (parts[0].length > 3) {
        parts[0] = parts[0].slice(0, 3)
      }
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 0) {
        val = '0'
      } else if (numericVal > 100) {
        val = '100'
      }
      return val
    },
    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberFlexi(value) {
      const regexNumber = /^[0-9.]+$/

      if (Number(value) >= 100) return ''
      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    async handleUpdateComp() {
      // const valid = await this.$refs.infoTippingForm.validate()
      // if (!valid) return

      const data = {
        name: this.compName,
        ...(this.guestName !== this.comp.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.formatDataDes(this.participantsDes),
        remember: this.remember,
        scratchings: this.scratchings,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 60)),
        afternoonLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 30)),
        useFlexiRollOverWallet: 0,
        ...(new Date() <= new Date(this.comp.morningLockout) && { apiPrice: Number(this.apiPrice) }),
        // ...(new Date() <= new Date(this.comp.morningLockout)
        //     && this.selectedFlexiRollOverPool && { flexiRollOverAmount: Number(this.flexiRollOverAmount) }),
        ...(this.limitBetslip !== this.comp.joinTimesLimit && {
          joinTimesLimit: Number(this.limitBetslip),
        }),
        ...(this.manualBetSlipEntry !== this.comp.manualBetSlipEntry && {
          manualBetSlipEntry: Number(this.manualBetSlipEntry),
        }),
        prizeType: this.selectedTransferPrize,
        isJackpotEnabled: false,
      }

      const payload = { data, competitionId: this.$route.params.id }
      const res = await this.updateComp(payload)
      if (res) {
        this.showToastSuccess('Success', 'Update this competition successfully!')
        this.getCompDetail(this.$route.params.id)
      } else {
        this.resetData()
      }
    },
    resetData() {
      this.compName = this.comp.name
      this.openDate = new Date(this.comp.openForEntry)
      this.competitionDate = new Date(this.comp.competitionDate)
      this.endDate = new Date(this.comp.endDate)
      this.moreInfo = this.comp.moreInfo
      this.remember = this.comp.remember
      this.scratchings = this.comp.scratchings
      this.percentWinner = this.comp.percentWinner
      this.participantsDes = this.comp.participantDescriptions
      this.latePrice = this.comp.latePrice
      this.priceTo = this.comp.price

      this.morningLockout = new Date(this.comp.morningLockout)
      this.afternoonLockout = new Date(this.comp.afternoonLockout)
      this.optionsFlexi = this.comp.flexiValues
      this.selectedFlexiRollOverPool = this.comp.selectedFlexiRollOverPool
      this.limitBetslip = this.comp.joinTimesLimit
      this.manualBetSlipEntry = this.comp.manualBetSlipEntry
      this.selectedTransferPrize = this.comp.prizeType
    },
    handleAddMoreOptionFlexi() {
      this.optionsFlexi.push('')
    },
    handleRemoveOptionFlexi() {
      this.optionsFlexi.pop()
    },
    formatPriceRollOver(value) {
      if (value < 0) return null
      if (value > this.maxValueFlexiRollOver()) return this.maxValueFlexiRollOver()
      return value
    },
    maxValueFlexiRollOver() {
      if (!this.comp.flexiRollOverAmount) return this.flexiRollOverWallet
      return this.comp.flexiRollOverAmount + this.flexiRollOverWallet
    },
    formatDataDes(data) {
      return data
        .replace(/<p(\s[^>]*)?>/g, '<div$1>')
        .replace(/<\/p>/g, '</div>')
    },

  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
      formatDate,
      formatDateSend,
      formatCurrencyBuiltInFunc,
      formatDateTimeLocal,
      formatTime,
    }
  },
}
</script>

  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-modal {
    color: black !important;
  }
  </style>
