<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Final Payout:
        </h4>
        <b-button
          :variant="dataDetail.isCompleted ? '' : 'danger'"
          class="btn-set ml-2"
          :disabled="dataDetail.isCompleted"
          style="margin-top: -8px;"
          @click="showModal('end-comp')"
        >
          <template> End Showdown</template>
        </b-button>
        <b-button
          v-if="dataDetail.isCompleted"
          variant="primary"
          class="btn-set ml-2"
          style="margin-top: -8px;"
          @click="showModal('approve')"
        >
          Approve
        </b-button>

      </div>

      <b-row class="mb-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>prizes</label>
        </b-col>
      </b-row>

      <b-table
        ref="refPrizesListTable"
        :items="fetchPrizes"
        :fields="tableColumns"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="loading"
            class="text-center my-2"
          >
            <p>Loading... Please wait</p>
          </div>
          <div
            v-if="fetchPrizes.length === 0 && !loading"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(id)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold id-column">
              {{ item.groupId ? '' : item.userId }}
            </div>
          </b-media>
        </template>
        <!-- Column: user name -->
        <template #cell(name)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.groupId ? '' : item.username }}
            </div>
          </b-media>
        </template>
        <!-- Column: betid -->
        <template #cell(betid)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.competitionUserId }}
            </div>
          </b-media>
        </template>
        <!-- Column: prize order -->
        <template #cell(prizeOrder)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.rank ? toCardinal(item.rank) : null }}
            </div>
          </b-media>
        </template>
        <!-- Column:Prize value -->
        <template #cell(prizeValue)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.prizeValue }}
            </div>
          </b-media>
        </template>

        <!-- Column: status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="resolveStatusVariant(item.status)"
            >
              {{ resolveStatusText(item.status) }}
            </b-badge>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} prizes
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPrizes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="loadingTransfer "
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-black">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingTransfer " />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BMedia,
  BBadge,
  VBModal,
  BCardText,
  BRow,
  BPagination,
  BCol,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import {
  formatDateQuery, formatTime, formatDateTimeLocal, convertToUTC, formatCurrencyBuiltInFunc, toCardinal,
} from '@/helpers/utils'
import { objectProp } from '@/helpers/props.js'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import { computed, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BMedia,
    BBadge,
    Spinner,
    BCardText,
    BRow,
    BPagination,
    BCol,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    comp: objectProp(),

  },

  data() {
    return {
      listPrize: [],
      modal: {
        content: '',
        title: '',
      },
      type: '',
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/prizeTransfer', ['loadingTransfer']),
    ...mapState('races/listLeaderboard', [
      'loading',
      'total',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapActions({
      getCompDetail: 'races/compDetail/getCompDetail',
      getListPrizes: 'races/listLeaderboard/getListPrizes',
      setPrizeTransfer: 'races/prizeTransfer/setPrizeTransfer',
      endCompAsync: 'races/endComp/endCompAsync',
    }),
    async fetchPrizes() {
      const data = await this.getListPrizes({
        limit: this.perPage,
        page: this.currentPage,
        competitionId: this.$route.params.id,
      })

      if (!data && data.length <= 0) return this.showToastError(false, 'Error fetching prizes list')
      this.totalPrizes = this.total

      return data
    },
    async handleSubmitModal(event) {
      event.preventDefault()
      if (this.type === 'end-comp') return this.handleEndComp()
      const res = await this.setPrizeTransfer({ competitionId: this.$route.params.id })
      if (res) {
        setTimeout(() => this.refetchData(), 5000)
        this.showToastSuccess('Success', 'Please await around 5s for processing transfer all prizes.')
        this.$refs.modal.hide()
      }
    },

    async handleEndComp() {
      const res = await this.endCompAsync(this.$router.currentRoute.params.id)
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'End showdown successfully')
        this.$refs.modal.hide()
      } else {
        this.showToastError('Error', 'End showdown failed')
      }
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type) {
      this.type = type
      if (type === 'end-comp') {
        this.setModal(
          'End Showdown', 'Do you want to end this showdown?',
        )
      }
      if (type === 'approve') {
        this.setModal(
          'Approve Prize',
          'Do you want to Approve all Prize? You will not be able to undo it after confirmation',
        )
      }
      this.$refs.modal.show()
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const tableColumns = [
      { key: 'id', label: 'user id' },
      { key: 'name', label: 'user name' },
      { key: 'betid' },
      { key: 'prizeOrder', label: 'prize order' },
      { key: 'prizeValue', label: 'Prize value' },
      { key: 'status' },
    ]

    const perPage = ref(100)
    const totalPrizes = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]

    const refPrizesListTable = ref(null)

    const refetchData = () => {
      refPrizesListTable.value.refresh()
    }

    const resolveStatusText = status => {
      if (status === 'waiting') return 'Waiting'
      if (status === 'in_preview') return 'In preview'
      if (status === 'approved') return 'Approved'
      if (status === 'transferred') return 'transferred'
      // return 'primary'
    }

    const resolveStatusVariant = status => {
      if (status === 'waiting') return 'light-warning'
      if (status === 'in_preview') return 'light-warning'
      if (status === 'approved') return 'light-primary'
      if (status === 'transferred') return 'light-success'
      // return 'primary'
    }

    const dataMeta = computed(() => {
      const localItemsCount = refPrizesListTable.value ? refPrizesListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPrizes.value,
      }
    })
    watch([currentPage, perPage], () => {
      refetchData()
    })

    return {
      tableColumns,
      refPrizesListTable,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,

      showToastSuccess,
      showToastError,

      convertToUTC,
      resolveStatusText,
      resolveStatusVariant,

      formatCurrencyBuiltInFunc,
      toCardinal,
      refetchData,

      perPageOptions,
      dataMeta,

      perPage,
      currentPage,
      totalPrizes,
    }
  },
}
</script>

      <style lang="scss">
      .dark-layout .dropdown-menu .dropdown-item.disabled {
        color: red !important;
      }
      </style>

      <style scoped lang="scss">
      @import '@core/scss/vue/libs/vue-flatpicker.scss';

      .v-select .vs__dropdown-toggle,
      .v-select .vs__search {
        cursor: pointer;
      }

      .card-body {
        padding: 0 !important;
      }

      .per-page-selector {
        width: 90px;
      }

      .id-column {
        max-width: 150px;
        min-width: 100px;
        word-break: break-word;
      }

      @import '@core/scss/vue/libs/vue-select.scss';
      </style>
