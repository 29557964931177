var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_vm._l((_vm.listMidPayout),function(l,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"BookIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Mid Payout Round "+_vm._s(l.weekNumber)+": ")]),_c('b-button',{staticClass:"btn-set ml-2",staticStyle:{"margin-top":"-8px"},attrs:{"variant":_vm.isApproveMid(l.weekNumber) ? '' : 'primary',"disabled":_vm.isApproveMid(l.weekNumber)},on:{"click":function($event){return _vm.showModal(l.weekNumber )}}},[_vm._v(" Approve ")])],1),_c('b-table',{ref:"refMidPrizeList",refInFor:true,attrs:{"items":_vm.filterListPrize(l.weekNumber),"fields":_vm.tableColumns,"responsive":"","show-empty":"","primary-key":"id"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loadingMid)?_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v("Loading... Please wait")])]):_vm._e()]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.userId)+" ")])])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.username)+" ")])])]}},{key:"cell(betid)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.competitionUserId)+" ")])])]}},{key:"cell(prizeOrder)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.rank ? _vm.toCardinal(item.rank) : null)+" ")])])]}},{key:"cell(prizeValue)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.prizeValue)+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-badge',{attrs:{"pill":"","variant":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveStatusText(item.status))+" ")])],1)]}}],null,true)})],1)}),_c('b-modal',{ref:"modal",attrs:{"title":_vm.modal.title,"centered":"","cancel-variant":"outline-secondary","ok-disabled":_vm.loadingApproveMid},on:{"ok":_vm.handleSubmitModal},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.loadingApproveMid)?_c('Spinner'):_c('span',[_vm._v("Submit")])]},proxy:true}])},[_c('b-card-text',{staticClass:"text-black"},[_vm._v(" "+_vm._s(_vm.modal.content)+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }