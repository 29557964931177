var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"BookIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" DATA COMP ")])],1),_c('p',[_c('span',{staticClass:"title"},[_vm._v("Real Cash Betslips:")]),_vm._v(" "+_vm._s(_vm.compStatistic.cashBetSlip))]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("Free Betslips:")]),_vm._v(" "+_vm._s(_vm.compStatistic.freeBetSlip))]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("Total Betslips:")]),_vm._v(" "+_vm._s(_vm.dataDetail.participants))]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("Participants Unique:")]),_vm._v(" "+_vm._s(_vm.dataDetail.uniqueParticipants))]),_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"BookIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" UPDATE COMP ")])],1),_c('validation-observer',{ref:"infoTippingForm"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"name"}},[_vm._v("NAME (logged in)")])]),_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.compName),callback:function ($$v) {_vm.compName=$$v},expression:"compName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"name"}},[_vm._v("NAME (not logged in)")])]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.guestName),callback:function ($$v) {_vm.guestName=$$v},expression:"guestName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"openDate"}},[_vm._v("Showdown Open Date/Time")])]),_c('validation-provider',{attrs:{"name":"openDate","vid":"openDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"openDate","config":{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                    // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                    }},model:{value:(_vm.openDate),callback:function ($$v) {_vm.openDate=$$v},expression:"openDate"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"competitionDate"}},[_vm._v("First Leg Start Date/Time")])]),_c('validation-provider',{attrs:{"name":"competitionDate","vid":"competitionDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"competitionDate","config":{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                    // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                    }},model:{value:(_vm.competitionDate),callback:function ($$v) {_vm.competitionDate=$$v},expression:"competitionDate"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"END DATE/TIME"}},[_vm._v("END DATE/TIME")])]),_c('validation-provider',{attrs:{"name":"endTime","vid":"endTime"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"endDate","config":{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                      disable: [{ from: '1970-01-01', to: _vm.formatDate(_vm.disableDate) }],
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"more info"}},[_vm._v("MORE INFO")])]),_c('validation-provider',{attrs:{"name":"moreInfo","vid":"moreInfo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.moreInfo),callback:function ($$v) {_vm.moreInfo=$$v},expression:"moreInfo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"remember"}},[_vm._v("REMEMBER")])]),_c('validation-provider',{attrs:{"name":"remember","vid":"remember","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"scratchings"}},[_vm._v("SCRATCHINGS")])]),_c('validation-provider',{attrs:{"name":"scratchings","vid":"scratchings","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.scratchings),callback:function ($$v) {_vm.scratchings=$$v},expression:"scratchings"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"entry fee"}},[_vm._v("ENTRY FEE")])]),_c('validation-provider',{attrs:{"name":"priceTo","vid":"priceTo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.priceTo),callback:function ($$v) {_vm.priceTo=$$v},expression:"priceTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"re buy fee"}},[_vm._v("RE BUY FEE")])]),_c('validation-provider',{attrs:{"name":"latePrice","vid":"latePrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.latePrice),callback:function ($$v) {_vm.latePrice=$$v},expression:"latePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"re buy fee"}},[_vm._v("LIMIT BETSLIP")])]),_c('validation-provider',{attrs:{"name":"limit betslip","vid":"limit betslip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"limit-value","formatter":_vm.formatNumberBetslip},model:{value:(_vm.limitBetslip),callback:function ($$v) {_vm.limitBetslip=$$v},expression:"limitBetslip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"re buy fee"}},[_vm._v("MANUAL BETSLIP ENTRY (optional)")])]),_c('b-form-input',{attrs:{"id":"limit-value","formatter":_vm.formatNumberBetslip},model:{value:(_vm.manualBetSlipEntry),callback:function ($$v) {_vm.manualBetSlipEntry=$$v},expression:"manualBetSlipEntry"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"re buy fee"}},[_vm._v("BONUS ADDITIONAL TICKET (optional)")])]),_c('b-form-input',{attrs:{"min":"0","type":"number","no-wheel":true,"placeholder":"Enter 100 for 0.5 tickets @ $50"},model:{value:(_vm.apiPrice),callback:function ($$v) {_vm.apiPrice=$$v},expression:"apiPrice"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"participants descriptions"}},[_vm._v("PARTICIPANTS DESCRIPTIONS")])]),_c('validation-provider',{attrs:{"name":"participantsDes","vid":"participantsDes","rules":"required|limitCharacters"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('vue2-tinymce-editor',{model:{value:(_vm.participantsDes),callback:function ($$v) {_vm.participantsDes=$$v},expression:"participantsDes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2 d-flex align-items-start"},[_c('b-col',{staticClass:"font-weight-bold",attrs:{"cols":"3"}},[_c('h4',[_vm._v("Transfer Prize By")])]),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-radio-group',{attrs:{"id":"transfer-prize","options":_vm.optionsTransferPrizeBy,"name":"transfer-prize"},model:{value:(_vm.selectedTransferPrize),callback:function ($$v) {_vm.selectedTransferPrize=$$v},expression:"selectedTransferPrize"}})],1)],1),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":_vm.loadingUpdateComp},on:{"click":_vm.handleUpdateComp}},[(_vm.loadingUpdateComp)?_c('Spinner'):[_vm._v(" Save changes ")]],2),_c('b-button',{attrs:{"variant":"outline-secondary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.resetData}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }