var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"BookIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Final Payout: ")]),_c('b-button',{staticClass:"btn-set ml-2",staticStyle:{"margin-top":"-8px"},attrs:{"variant":_vm.dataDetail.isCompleted ? '' : 'danger',"disabled":_vm.dataDetail.isCompleted},on:{"click":function($event){return _vm.showModal('end-comp')}}},[[_vm._v(" End Showdown")]],2),(_vm.dataDetail.isCompleted)?_c('b-button',{staticClass:"btn-set ml-2",staticStyle:{"margin-top":"-8px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showModal('approve')}}},[_vm._v(" Approve ")]):_vm._e()],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("prizes")])],1)],1),_c('b-table',{ref:"refPrizesListTable",attrs:{"items":_vm.fetchPrizes,"fields":_vm.tableColumns,"responsive":"","show-empty":"","primary-key":"id"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"text-center my-2"},[_c('p',[_vm._v("Loading... Please wait")])]):_vm._e(),(_vm.fetchPrizes.length === 0 && !_vm.loading)?_c('div',{staticClass:"text-center my-2"},[_vm._v(" No matching records found ")]):_vm._e()]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold id-column"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.userId)+" ")])])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.groupId ? '' : item.username)+" ")])])]}},{key:"cell(betid)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.competitionUserId)+" ")])])]}},{key:"cell(prizeOrder)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.rank ? _vm.toCardinal(item.rank) : null)+" ")])])]}},{key:"cell(prizeValue)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.prizeValue)+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-badge',{attrs:{"pill":"","variant":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveStatusText(item.status))+" ")])],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" prizes ")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalPrizes,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('b-modal',{ref:"modal",attrs:{"title":_vm.modal.title,"centered":"","cancel-variant":"outline-secondary","ok-disabled":_vm.loadingTransfer},on:{"ok":_vm.handleSubmitModal},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.loadingTransfer )?_c('Spinner'):_c('span',[_vm._v("Submit")])]},proxy:true}])},[_c('b-card-text',{staticClass:"text-black"},[_vm._v(" "+_vm._s(_vm.modal.content)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }