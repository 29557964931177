<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <!-- PRIZE POOL -->
      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Prize Pool:
        </h4>
        <h4 class="ml-2">
          {{ formatCurrencyBuiltInFunc(dataDetail.displayPrizePool ? dataDetail.displayPrizePool : 0) }}
        </h4>
        <b-button
          variant="primary"
          class="btn-set ml-2"
          style="margin-top: -8px;"
          @click="handleShowModalUpdatePrizePool"
        >
          Update Prize Pool
        </b-button>

      </div>

      <!-- MAIN PRIZE POOL -->
      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Total Prize
        </h4>
        <h4 class="ml-2">
          {{ formatCurrencyBuiltInFunc(dataDetail.mainPrizePool ? dataDetail.mainPrizePool : 0) }}
        </h4>
      </div>

      <!-- TABLE MAIN PRIZE -->
      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Main Prize - End of Season Comp Payout
        </h4>

        <b-button
          variant="primary"
          class="btn-set ml-2"
          style="margin-top: -8px;"
          @click="handleAddMainPrize"
        >
          Add Main Prize
        </b-button>

      </div>
      <b-table
        ref="refMainPrizeList"
        :items="fetchListMainPrize"
        :fields="columns"
        responsive
        primary-key="event"
        show-empty
      >
        <template #empty>
          <div
            v-if="fetchListMainPrize.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- value -->
        <template #cell(value)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.value ? `$${item.value}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- percent -->
        <template #cell(percent)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.percent ? `${item.percent}%` : '' }}
            </div>
          </b-media>
        </template>
        <!-- action -->
        <template #cell(action)="{ item }">

          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleShowModalDelete(item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleEdit(item, 'percent')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- TABLE MID PRIZE -->
      <div class="d-flex my-2">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Mid Payout
        </h4>
        <b-button
          variant="primary"
          class="btn-set ml-2"
          style="margin-top: -8px;"
          @click="handleAddMidPrize"
        >
          Add Mid Prize
        </b-button>
      </div>
      <b-table
        ref="refMidPrizeList"
        :items="fetchListMidPrize"
        :fields="columnsMidPayout"
        responsive
        primary-key="event"
        show-empty
      >
        <template #empty>
          <div
            v-if="fetchListMidPrize.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- value -->
        <template #cell(value)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.value ? `$${item.value}` : '' }}
            </div>
          </b-media>
        </template>
        <!-- percent -->
        <template #cell(percent)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.percent ? `${item.percent}%` : '' }}
            </div>
          </b-media>
        </template>

        <!-- action -->
        <template #cell(action)="{ item }">

          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleShowModalDelete(item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleEdit(item, 'value')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- MODAL LIST EVENT -->
      <b-modal
        ref="prize"
        hide-footer
        title="Prize"
        centered
        cancel-variant="outline-secondary"
        size="md"
        @hide="handleHideModal"
      >
        <div style="color: black;">
          <p
            v-if="updateItem"
            style="font-weight: bold; font-size: 18px;"
          >
            Order Prize:
            <b-badge
              v-if="updateItem"
              pill
              :variant="resolveStatusVariant(updateItem.order)"
            >
              {{ updateItem ? updateItem.order : '' }}
            </b-badge>
          </p>

          <!-- order -->
          <b-row
            v-if="!updateItem && addPrizeType !== 'mid'"
          >
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Order
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-input
                v-model="orderNumber"
                placeholder="eg. 1"
                :formatter="formatOrderNumber"
              />
            </b-col>
          </b-row>
          <!-- weekNumber -->
          <b-row
            v-if="addPrizeType === 'mid'"
            class="mt-1"
          >
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Week Number
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-input
                v-model="weekNumber"
                placeholder="eg. 1"
                :formatter="formatOrderNumber"
              />
            </b-col>
          </b-row>
          <!-- Pay Based on -->
          <b-row class="mt-1 d-flex align-items-start">
            <b-col
              cols="5"
              class="font-weight-bold"
            >
              <p>Pay Based on</p>
            </b-col>
            <b-col
              cols="6"
              class="d-flex align-items-center"
            >
              <!-- <b-form-radio-group
                id="show-flexi-amount"
                v-model="selectedPayout"
                :options="optionsPayout"
                name="show-flexi-amount"
                disabled
              /> -->
              <p> {{ selectedPayout }}</p>

            </b-col>
          </b-row>

          <!-- value -->
          <b-row
            v-if="selectedPayout"
          >
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Value
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-input
                v-model="value"
                placeholder="eg. 2"
                :formatter="formatNumber"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loadingPrize"
                @click="handleSavePrize"
              >
                <Spinner v-if="loadingPrize" />
                <template v-else>
                  Save
                </template>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>

      <!-- DELETE MODAL -->
      <b-modal
        ref="delete"
        title="Delete Prize"
        centered
        cancel-variant="outline-secondary"
        :ok-disabled="loadingDeletePrize"
        @ok="handleSubmitDeletePrizeModal"
      >
        <b-card-text>
          <h6>Do you want to delete this prize?</h6>
        </b-card-text>
        <template #modal-ok>
          <Spinner v-if="loadingDeletePrize" />
          <span v-else>Submit</span>
        </template>
      </b-modal>

      <!-- UPDATE PRIZE POOL MODAL -->
      <b-modal
        ref="prize-pool"
        hide-footer
        title="Prize Pool"
        centered
        cancel-variant="outline-secondary"
        size="md"
        @hide="handleHideModalPrizePool"
      >
        <div style="color: black;">

          <!-- Prize Pool -->
          <b-row
            class="mt-1"
          >
            <b-col
              cols="5"
              class="d-flex align-items-center"
            >
              <div class="font-weight-bold">
                Prize Pool
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex"
              style="flex-direction: column"
            >
              <b-form-input
                v-model="mainPrizePool"
                placeholder="eg. 1"
                :formatter="formatNumberPrizePool"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col class="d-flex justify-content-end mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="loadingUpdateComp"
                @click="handleSavePrizePool"
              >
                <Spinner v-if="loadingUpdateComp" />
                <template v-else>
                  Save
                </template>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BTable, BMedia, BCol, BRow, BCard, BButton, BFormInput, BBadge,
  BDropdown,
  BDropdownItem,
  BCardText,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import {
  formatDateQuery, formatTime, formatDateTimeLocal, convertToUTC, formatCurrencyBuiltInFunc, formatDateSend,
} from '@/helpers/utils'
import { arrayProp } from '@/helpers/props.js'
import useToastHandler from '@/services/toastHandler'
import { ref } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BTable,
    BMedia,
    BCol,
    BRow,
    BCard,
    // BDropdown,
    // BDropdownItem,
    BButton,
    Spinner,
    // BFormRadioGroup,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardText,
  },
  props: {
    listDetailEventProp: arrayProp(),
  },

  data() {
    return {
      updateItem: null,
      optionsPayout: [
        { text: 'Percent', value: 'percent' },
        { text: 'Value', value: 'value' },
      ],
      selectedPayout: 'percent',
      value: '',
      deleteItem: null,
      addPrizeType: null,
      orderNumber: null,
      weekNumber: null,
      mainPrizePool: null,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('tipping/create', ['loadingPrize', 'loadingDeletePrize']),
    ...mapState('races/updateComp', ['loadingUpdateComp']),
  },
  watch: {
    selectedPayout(val) {
      if (val && !this.updateItem) this.value = ''
    },
    dataDetail() {
      this.refMainPrizeList.refresh()
      this.refMidPrizeList.refresh()
    },
  },
  methods: {
    ...mapActions({
      createNewTippingPrize: 'tipping/create/createNewTippingPrize',
      getCompDetail: 'races/compDetail/getCompDetail',
      deleteTippingPrize: 'tipping/create/deleteTippingPrize',
      updateComp: 'races/updateComp/updateCompAsync',

    }),
    fetchListMainPrize() {
      const list = this.dataDetail.tippingPrizes ?? []
      return list.filter(i => !i.weekNumber)
    },
    fetchListMidPrize() {
      const list = this.dataDetail.tippingPrizes ?? []
      return list.filter(i => !!i.weekNumber)
    },
    handleAddMainPrize() {
      this.selectedPayout = 'percent'
      this.addPrizeType = 'main'
      this.$refs.prize.show()
    },
    handleEdit(item, data) {
      this.addPrizeType = data === 'value' ? 'mid' : 'main'
      this.updateItem = item
      this.selectedPayout = data
      this.value = data === 'percent' ? item.percent : item.value
      this.orderNumber = item.order
      this.weekNumber = item.weekNumber
      this.$refs.prize.show()
    },
    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (Number(value) > 100 && this.selectedPayout === 'percent') return 100
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 3) {
            return `${integerPart}.${decimalPart.slice(0, 3)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberPrizePool(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatOrderNumber(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    handleHideModal() {
      this.value = ''
      this.selectedPayout = 'percent'
      this.updateItem = null
      this.orderNumber = null
      this.weekNumber = null
    },
    async handleSavePrize() {
      if (!this.updateItem && (!this.orderNumber && this.addPrizeType !== 'mid')) return this.showToastError(false, 'Please fill all data!')
      if (!this.updateItem && !this.weekNumber && this.addPrizeType === 'mid') return this.showToastError(false, 'Please fill all data!')
      const res = await this.createNewTippingPrize({
        competitionId: this.$route.params.id,
        data: {
          prizes: [{
            order: this.addPrizeType === 'mid' ? 1 : this.orderNumber,
            value: this.selectedPayout === 'value' ? Number(this.value) : 0,
            type: 'main',
            percent: this.selectedPayout === 'percent' ? Number(this.value) : 0,
            weekNumber: this.addPrizeType === 'mid' ? Number(this.weekNumber) : 0,
          }],
        },
      })
      if (res) {
        await this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Update prize successfully!')
        this.$refs.prize.hide()
      }
    },
    handleShowModalDelete(item) {
      this.deleteItem = item
      this.$refs.delete.show()
    },
    async  handleSubmitDeletePrizeModal() {
      const res = await this.deleteTippingPrize({
        competitionId: this.$route.params.id,
        data: {
          weekNumber: this.deleteItem.weekNumber,
          order: this.deleteItem.order,
        },
      })
      if (res) {
        await this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Update prize successfully!')
        this.$refs.delete.hide()
      }
    },
    handleAddMidPrize() {
      this.selectedPayout = 'value'
      this.addPrizeType = 'mid'
      this.$refs.prize.show()
    },
    handleShowModalUpdatePrizePool() {
      this.mainPrizePool = this.dataDetail.displayPrizePool
      this.$refs['prize-pool'].show()
    },
    handleHideModalPrizePool() {
      this.mainPrizePool = null
    },
    async handleSavePrizePool() {
      const res = await this.updateComp({
        competitionId: this.$route.params.id,
        data: {
          displayPrizePool: Number(this.mainPrizePool),

          name: this.dataDetail.name,
          guestName: this.dataDetail.guestName,
          moreInfo: this.dataDetail.moreInfo,
          openForEntry: this.formatDateSend(this.dataDetail.openForEntry),
          competitionDate: this.formatDateSend(this.dataDetail.competitionDate),
          endDate: this.formatDateSend(this.dataDetail.endDate),
          participantDescriptions: this.dataDetail.participantDescriptions,
          remember: this.dataDetail.openFrememberorEntry,
          scratchings: this.dataDetail.scratchings,
          price: this.dataDetail.price,
          latePrice: this.dataDetail.latePrice,
          morningLockout: this.formatDateSend(this.dataDetail.morningLockout),
          afternoonLockout: this.formatDateSend(this.dataDetail.afternoonLockout),
          useFlexiRollOverWallet: 0,
          prizeType: this.dataDetail.prizeType,
          isJackpotEnabled: false,
          joinTimesLimit: this.dataDetail.joinTimesLimit,
          apiPrice: this.dataDetail.apiPrice,
        },
      })
      if (res) {
        await this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Update prize successfully!')
        this.$refs['prize-pool'].hide()
      }
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const columns = [
      {
        key: 'order',
      },
      {
        key: 'value',
        label: 'by value',
      },
      {
        key: 'percent',
        label: 'by percent',
      },
      {
        key: 'action',
      },
    ]
    const columnsMidPayout = [
      {
        key: 'order',
      },
      { key: 'weekNumber' },
      {
        key: 'value',
        label: 'by value',
      },
      {
        key: 'percent',
        label: 'by percent',
      },
      {
        key: 'action',
      },
    ]
    const refMainPrizeList = ref(null)
    const refMidPrizeList = ref(null)

    const resolveStatusVariant = order => {
      if (order === 1) return 'light-success'
      return 'light-primary'
    }

    return {
      columns,
      columnsMidPayout,
      refMainPrizeList,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,

      showToastSuccess,
      showToastError,

      refMidPrizeList,

      convertToUTC,

      resolveStatusVariant,

      formatCurrencyBuiltInFunc,
      formatDateSend,
    }
  },
}
</script>

  <style lang="scss">
  .dark-layout .dropdown-menu .dropdown-item.disabled {
    color: red !important;
  }
  </style>

  <style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .v-select .vs__dropdown-toggle,
  .v-select .vs__search {
    cursor: pointer;
  }

  .card-body {
    padding: 0 !important;
  }

  .per-page-selector {
    width: 90px;
  }

  .id-column {
    max-width: 150px;
    min-width: 100px;
    word-break: break-word;
  }

  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
