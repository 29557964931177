<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-1"
        >

          <b-button
            v-b-modal.modal-abandon
            :variant="dataDetail.status === 'abandon' ? '' : 'danger'"
            class="mb-1 mb-sm-0 mr-1"
            :disabled="dataDetail.status === 'abandon'"
          >
            <template> Abandon Showdown</template>
          </b-button>
        </b-col>
      </b-row>

      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Info</span>
          </template>

          <UpdateInfoTab
            :comp="dataDetail"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Events -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Events</span>
          </template>
          <UpdateEventsTab
            v-if="tabIndex === 1"
            :comp="dataDetail"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Prizes -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Prizes</span>
          </template>
          <PrizeTab
            v-if="tabIndex === 2"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: MID PAYOUT -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Mid Payout</span>
          </template>
          <PayoutTab
            v-if="tabIndex === 3"
            :comp="dataDetail"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: MAIN PAYOUT -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Main Payout</span>
          </template>
          <MainPayoutTab
            v-if="tabIndex === 4"
            :comp="dataDetail"
            class="mt-2 pt-75"
          />
        </b-tab>

      </b-tabs>
    </b-card>

    <b-modal
      id="modal-abandon"
      ref="modalAbandonComp"
      title="Confirm Abandon Showdown"
      centered
      cancel-variant="outline-secondary"
      @ok="handleAbandonShowdown"
      @hidden="handleHideModal"
    >
      <b-card-text class="text-title">
        <b-form @submit="onSubmit">
          <b-form-input
            hidden
            type="text"
            autocomplete="username"
          />
          <label
            for="password"
            class="w-full"
          >
            Enter your password

            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="password"
                :type="passwordConfirm"
                autocomplete="new-password"
                @update="onChange()"
              />

              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconConfirm"
                  class="cursor-pointer"
                  @click="togglePasswordConfirm"
                />
              </b-input-group-append>
            </b-input-group>
            <span
              v-if="isErrorRequired"
              class="text-danger"
            >Password is required</span>
          </label>
        </b-form>
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="loadingEndComp" />
        <span v-else>Submit</span>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingPage from '@core/components/LoadingPage'
import {
  BButton,
  BCard, BCardText, BCol, BForm, BFormInput, BInputGroup, BInputGroupAppend, BRow, BTab, BTabs, VBModal,
} from 'bootstrap-vue'
import { ENTRY_TYPE_BACK, ENTRY_TYPE_FRONT } from '@/@core/utils/enum/comp'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
import UpdateInfoTab from './UpdateInfoTab.vue'
import UpdateEventsTab from './UpdateEventsTab.vue'
import PrizeTab from './PrizeTab.vue'
import PayoutTab from './PayoutTab.vue'
import MainPayoutTab from './MainPayoutTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    LoadingPage,

    UpdateInfoTab,
    UpdateEventsTab,
    PrizeTab,
    PayoutTab,
    MainPayoutTab,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BCardText,
    BRow,
    BCol,
    Spinner,

  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      modal: {
        title: '',
        content: '',
      },
      submitType: {
        abandon: 1,
        endComp: 2,
      },
      type: null,
      password: null,
      isErrorRequired: false,
      passwordConfirm: 'password',

      code: null,
      errorCode: '',
      listSeletedUser: [],

      csvData: [],
      formatCsvData: [],
      totalback: null,
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/endComp', ['loadingEndComp']),
    passwordToggleIconNew() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    switch (this.$route.query.tab) {
      case 'events':
        this.tabIndex = 1
        break
      case 'prizes':
        this.tabIndex = 2
        break
      case 'mid-payout':
        this.tabIndex = 3
        break
      case 'main-payout':
        this.tabIndex = 4
        break
      default:
        break
    }
    this.loading = true
    await this.getCompDetail(this.$route.params.id)
    this.loading = false
  },

  methods: {
    ...mapActions({
      getCompDetail: 'races/compDetail/getCompDetail',
      abandonComp: 'races/endComp/abandonComp',
    }),
    onSubmit(event) {
      event.preventDefault()
    },
    onChange() {
      this.isErrorRequired = false
    },
    handleHideModal() {
      this.password = null
      this.passwordConfirm = 'password'
    },
    togglePasswordConfirm() {
      this.passwordConfirm = this.passwordConfirm === 'password' ? 'text' : 'password'
    },
    async handleAbandonShowdown(event) {
      event.preventDefault()

      if (!this.password) {
        this.isErrorRequired = true
        return
      }

      const res = await this.abandonComp({
        id: this.$router.currentRoute.params.id,
        password: this.password,
      })
      if (res) {
        this.getCompDetail(this.$route.params.id)
        this.showToastSuccess('Success', 'Abandon showdown successfully')
      }
      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modalAbandonComp.hide()
        })
      }
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      ENTRY_TYPE_FRONT,
      ENTRY_TYPE_BACK,
      showToastSuccess,
      showToastError,
    }
  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}

.text-title {
  color: #000;
}
.w-full{
  width: 100%;
  display: block;
}
</style>
