<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <!-- MID PAYOUT -->
      <div
        v-for="(l, index) in listMidPayout"
        :key="index"
      >
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Mid Payout Round {{ l.weekNumber }}:
          </h4>
          <b-button
            :variant="isApproveMid(l.weekNumber) ? '' : 'primary'"
            class="btn-set ml-2"
            style="margin-top: -8px;"
            :disabled="isApproveMid(l.weekNumber)"
            @click="showModal(l.weekNumber )"
          >
            Approve
          </b-button>

        </div>

        <b-table
          ref="refMidPrizeList"
          :items="filterListPrize(l.weekNumber)"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="id"
        >
          <template #empty>
            <div
              v-if="loadingMid"
              class="text-center my-2"
            >
              <p>Loading... Please wait</p>
            </div>

          </template>

          <!-- Column: ID -->
          <template #cell(id)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold id-column">
                {{ item.groupId ? '' : item.userId }}
              </div>
            </b-media>
          </template>
          <!-- Column: user name -->
          <template #cell(name)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.groupId ? '' : item.username }}
              </div>
            </b-media>
          </template>
          <!-- Column: betid -->
          <template #cell(betid)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.competitionUserId }}
              </div>
            </b-media>
          </template>
          <!-- Column: prize order -->
          <template #cell(prizeOrder)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.rank ? toCardinal(item.rank) : null }}
              </div>
            </b-media>
          </template>
          <!-- Column:Prize value -->
          <template #cell(prizeValue)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center font-weight-bold">
                {{ item.prizeValue }}
              </div>
            </b-media>
          </template>

          <!-- Column: status -->
          <template #cell(status)="{ item }">
            <b-media vertical-align="center">
              <b-badge
                pill
                :variant="resolveStatusVariant(item.status)"
              >
                {{ resolveStatusText(item.status) }}
              </b-badge>
            </b-media>
          </template>
        </b-table>

      </div>

      <b-modal
        ref="modal"
        :title="modal.title"
        centered
        cancel-variant="outline-secondary"
        :ok-disabled="loadingApproveMid"
        @ok="handleSubmitModal"
      >
        <b-card-text class="text-black">
          {{ modal.content }}
        </b-card-text>
        <template #modal-ok>
          <Spinner v-if="loadingApproveMid" />
          <span v-else>Submit</span>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BMedia,
  BBadge,
  VBModal,
  BCardText,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import {
  formatDateQuery, formatTime, formatDateTimeLocal, convertToUTC, formatCurrencyBuiltInFunc, toCardinal,
} from '@/helpers/utils'
import { objectProp } from '@/helpers/props.js'
import useToastHandler from '@/services/toastHandler'
import { ref } from '@vue/composition-api'
import Spinner from '@core/components/Spinner'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BMedia,
    BBadge,
    BCardText,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    comp: objectProp(),
  },

  data() {
    return {
      listPrize: [],
      modal: {
        content: '',
        title: '',
      },
      type: '',
    }
  },
  computed: {
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('tipping/payout', ['loadingMid', 'loadingApproveMid']),
    listMidPayout() {
      return this.comp.tippingPrizes.filter(i => i.weekNumber)
    },
  },
  watch: {

  },
  async mounted() {
    this.loadListPrize()
  },
  methods: {
    ...mapActions({
      getCompDetail: 'races/compDetail/getCompDetail',
      getListMidPayout: 'tipping/payout/getListMidPayout',
      approveMidPayout: 'tipping/payout/approveMidPayout',

    }),
    async loadListPrize() {
      const newList = this.listMidPayout
        .map(async i => {
          const data = await this.getListMidPayout({
            competitionId: this.$route.params.id,
            weekNumber: i.weekNumber,
          })
          return {
            ...i,
            list: data,
          }
        })

      Promise.all(newList).then(result => {
        this.listPrize = result
      })
    },
    filterListPrize(week) {
      if (this.listPrize.length === 0) return []
      return this.listPrize.find(i => i.weekNumber === week).list
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type) {
      this.type = type
      this.setModal(
        'Approve Prize',
        `Do you want to Approve Mid Payout Round ${type}? You will not be able to undo it after confirmation`,
      )
      this.$refs.modal.show()
    },
    async handleSubmitModal(event) {
      event.preventDefault()

      const res = await this.approveMidPayout({ competitionId: this.$route.params.id, weekNumber: this.type })
      if (res) {
        setTimeout(() => {
          this.loadListPrize()
          this.refetchData()
        }, 5000)
        this.showToastSuccess('Success', 'Please await around 5s for processing transfer all prizes.')
        this.$refs.modal.hide()
      }
    },
    isApproveMid(number) {
      const find = this.listPrize.find(i => i.weekNumber === number)
      if (!find || !find.list || !find.list.length) return true
      return find.list[0].status === 'transferred'
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const tableColumns = [
      { key: 'id', label: 'user id' },
      { key: 'name', label: 'user name' },
      { key: 'betid' },
      { key: 'prizeOrder', label: 'prize order' },
      { key: 'prizeValue', label: 'Prize value' },
      { key: 'status' },
    ]

    const refMidPrizeList = ref(null)

    const refetchData = () => {
      refMidPrizeList.value.refresh()
    }

    const resolveStatusText = status => {
      if (status === 'waiting') return 'Waiting'
      if (status === 'in_preview') return 'In preview'
      if (status === 'approved') return 'Approved'
      if (status === 'transferred') return 'transferred'
    }

    const resolveStatusVariant = status => {
      if (status === 'waiting') return 'light-warning'
      if (status === 'in_preview') return 'light-warning'
      if (status === 'approved') return 'light-primary'
      if (status === 'transferred') return 'light-success'
    }

    return {
      tableColumns,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,

      showToastSuccess,
      showToastError,

      refMidPrizeList,

      convertToUTC,
      resolveStatusText,
      resolveStatusVariant,

      formatCurrencyBuiltInFunc,
      toCardinal,
      refetchData,
    }
  },
}
</script>

    <style lang="scss">
    .dark-layout .dropdown-menu .dropdown-item.disabled {
      color: red !important;
    }
    </style>

    <style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .v-select .vs__dropdown-toggle,
    .v-select .vs__search {
      cursor: pointer;
    }

    .card-body {
      padding: 0 !important;
    }

    .per-page-selector {
      width: 90px;
    }

    .id-column {
      max-width: 150px;
      min-width: 100px;
      word-break: break-word;
    }

    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
