export const CompAction = {
  PAUSE: 'pause',
  DELETE: 'delete',
}

export const UserStatusEnum = {
  unblock: 0,
  blocked: 1,
}

export const ENTRY_TYPE_FRONT = 0
export const ENTRY_TYPE_BACK = 1
